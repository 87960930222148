import React, { useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { showSignInModal } from 'redux/actions/modalActions';
import Button from 'react-bootstrap/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { store } from 'react-notifications-component';
import {
  REQUIRED_SIGNIN_FOR_CHECKOUT,
  REQUIRED_SIGNIN_FOR_SAVEFORLATER,
} from 'consts/form';

import { getPresetSubTotalPrice, getTotalQuantity } from 'utils/preset.util';
import SaveForLaterModal from './SaveForLaterModal';
import CheckoutInfo from './CheckoutInfo';
import GrandTotalPrice from './TotalPrice';

function OrderDetails({
  preset,
  deliveryPriceCents,
  checkoutData,
  saveCustomPreset,
  createGroupOrderForPreset,
  setCurrentPresetItemFromCheckoutData,
  removeCheckoutItem,
  onCheckoutClick,
  preOrderNoticeHour,
  showMinHourNoticeError,
  canShareLink,
  canSaveUpdate,
  setShowSaveForLaterModal,
  showSaveForLaterModal,
  typeDetails,
  buttons,
}) {
  const dispatch = useDispatch();
  const userSignedIn = useSelector(state => get(state, 'user.user.confirmed'));
  const receivingMethod = useSelector(state => state.receivingMethod);
  const { chefs } = preset;
  const showGroupOrderButton = !!(chefs && chefs.length === 1);
  // console.log(`show group order: ${showGroupOrderButton}`);

  const subTotal = getPresetSubTotalPrice(checkoutData);
  const savedForLater = preset.presetType === 'saved_for_later';
  const publicPreset = !savedForLater;
  const totalPriceCents =
    receivingMethod === 'delivery'
      ? (subTotal + deliveryPriceCents) * (1 + preset.taxRate)
      : subTotal * (1 + preset.taxRate);

  const totalQuantity = useMemo(() => {
    return getTotalQuantity(checkoutData);
  }, [checkoutData]);

  const copyToClipboard = (
    <CopyToClipboard text={preset.sharedUrl}>
      <button
        className="btn btn-outline-primary mt-3 btn-clipboard"
        type="button"
        onClick={() => {
          store.addNotification({
            title: 'URL Copied!',
            message: 'You can now paste the URL',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          });
        }}
      >
        <p>{preset.sharedUrl}</p>
        <span>Share This</span>
        <img src="/assets/img/share.svg" alt="clipboard" />
      </button>
    </CopyToClipboard>
  );

  return (
    <div
      className="card detailed-view--order-details order-details modal-mobile"
      id="sourceOrderDetails"
    >
      <div className="card-header">
        Order Details
        {totalQuantity > 0 && (
          <span className="badge badge-primary badge-pill">
            {totalQuantity}
          </span>
        )}
      </div>
      <CheckoutInfo
        {...{
          preset,
          deliveryPriceCents,
          checkoutData,
          setCurrentPresetItemFromCheckoutData,
          removeCheckoutItem,
          preOrderNoticeHour,
          showMinHourNoticeError,
        }}
      />
      <div className="card-footer">
        <>
          <ul className="total" style={{ margin: 0 }}>
            <li style={{ margin: 0, fontWeight: 500 }}>
              <span>Total Price&nbsp;</span>
              <span>
                <GrandTotalPrice
                  totalPriceCents={subTotal ? totalPriceCents : 0}
                />
              </span>
            </li>
          </ul>

          {userSignedIn && (
            <>
              {buttons}
              {typeDetails && (
                <Button
                  className="btn btn-primary btn-block mt-3"
                  type="button"
                  onClick={() => {
                    onCheckoutClick();
                  }}
                >
                  <span className="hide-mobile">Checkout</span>
                  <span className="hide-desktop">Pay</span>
                </Button>
              )}
              {canShareLink && typeDetails && copyToClipboard}
              {publicPreset && canSaveUpdate && typeDetails && (
                <button
                  className="btn btn-outline-primary btn-block mt-3"
                  type="button"
                  onClick={() => {
                    setShowSaveForLaterModal(true);
                  }}
                >
                  Save Cart
                </button>
              )}
              {showGroupOrderButton && typeDetails && (
                <button
                  className="btn btn-outline-primary btn-block mt-3"
                  type="button"
                  onClick={() => {
                    createGroupOrderForPreset(preset.id);
                  }}
                >
                  Create Group Order
                </button>
              )}
            </>
          )}
          {!userSignedIn && (
            <>
              <Button
                className="btn btn-primary btn-block mt-3"
                type="button"
                onClick={() => {
                  // console.log(checkoutData);
                  dispatch(showSignInModal(REQUIRED_SIGNIN_FOR_CHECKOUT));
                }}
              >
                <span className="hide-mobile">Checkout</span>
                <span className="hide-desktop">Pay</span>
              </Button>
              {canShareLink && copyToClipboard}
              {canSaveUpdate && (
                <button
                  className="btn btn-outline-primary btn-block mt-3"
                  type="button"
                  onClick={() => {
                    dispatch(showSignInModal(REQUIRED_SIGNIN_FOR_SAVEFORLATER));
                  }}
                >
                  Save Cart
                </button>
              )}
              {showGroupOrderButton && (
                <button
                  className="btn btn-outline-primary btn-block mt-3"
                  type="button"
                  onClick={() => {
                    dispatch(showSignInModal(REQUIRED_SIGNIN_FOR_SAVEFORLATER));
                  }}
                >
                  Create Group Order
                </button>
              )}
            </>
          )}

          <SaveForLaterModal
            show={showSaveForLaterModal}
            onClose={() => {
              setShowSaveForLaterModal(false);
            }}
            onSubmit={saveCustomPreset}
          />
        </>
      </div>
    </div>
  );
}

OrderDetails.propTypes = {
  preset: PropTypes.shape(),
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
  deliveryPriceCents: PropTypes.number,
  saveCustomPreset: PropTypes.func,
  createGroupOrderForPreset: PropTypes.func,
  setCurrentPresetItemFromCheckoutData: PropTypes.func,
  removeCheckoutItem: PropTypes.func,
  onCheckoutClick: PropTypes.func,
  setShowSaveForLaterModal: PropTypes.func,
  showSaveForLaterModal: PropTypes.bool,
  preOrderNoticeHour: PropTypes.number,
  showMinHourNoticeError: PropTypes.bool,
  canShareLink: PropTypes.bool,
  canSaveUpdate: PropTypes.bool,
  typeDetails: PropTypes.bool,
  buttons: PropTypes.arrayOf(),
};

OrderDetails.defaultProps = {
  preset: {},
  checkoutData: [],
  deliveryPriceCents: 0,
  preOrderNoticeHour: 0,
  showMinHourNoticeError: false,
  onCheckoutClick: () => {},
  saveCustomPreset: () => {},
  createGroupOrderForPreset: () => {},
  setCurrentPresetItemFromCheckoutData: () => {},
  removeCheckoutItem: () => {},
  setShowSaveForLaterModal: () => {},
  canShareLink: true,
  canSaveUpdate: true,
  typeDetails: true,
  showSaveForLaterModal: false,
  buttons: [],
};

export default OrderDetails;
