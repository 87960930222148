import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import mapValues from 'lodash/mapValues';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  getPresetItemQuantity,
  getPresetItemTotalPrice,
} from 'utils/preset.util';
import { INVALID_INTEGER } from 'consts/form';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import FormError from '../FormError';

import './index.scss';
import OrderError from '../OrderError';

const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const validationSchema = yup.object().shape({
  specialInstruction: yup.string(),
  quantity: yup
    .number()
    .integer(INVALID_INTEGER)
    .min(0, INVALID_INTEGER),
  menuItemsMapping: yup.lazy(obj =>
    yup.object(
      mapValues(obj, () => {
        return yup
          .number()
          .integer(INVALID_INTEGER)
          .min(0, INVALID_INTEGER);
      }),
    ),
  ),
});

const onQuantityInput = (e, setFieldValue) => {
  const { currentTarget } = e;
  // now need to save only integers
  const newValue =
    parseInt(currentTarget.value.replace(/[^0-9]/g, ''), 10) || 0;
  setFieldValue(currentTarget.name, newValue);
};

const onQuantityChange = (name, currentValue, setFieldValue, added = 1) => {
  const newValue = Math.max(parseInt(currentValue, 10) + added, 0);
  setFieldValue(name, newValue);
};

const PresetItemView = ({
  presetItem,
  initialValues,
  chefInfo,
  onClose,
  onSubmit,
}) => {
  const [minOrderSelectionValid, setMinOrderSelectionValid] = useState(true);
  if (!presetItem) {
    return null;
  }

  const {
    name,
    description,
    averageRating,
    images,
    menuItems,
    priceCents,
    dietaryTypes,
  } = presetItem;

  const parsedDescription =
    description && description.replace(/(\r\n|\n|\r)/gm, '<br />');

  return (
    <Modal backdrop="static" show size="lg" onHide={onClose}>
      <button className="close" type="button" onClick={onClose} />
      <Modal.Body className="modal-body modal--edit-qty">
        <div className="left">
          <div className="d-flex justify-content-between">
            <div>
              <h2>{name}</h2>
              <div className="star-ratings-sprite">
                <span
                  style={{ width: `${averageRating * 20}%` }}
                  className="star-ratings-sprite-rating"
                />
              </div>
              <div className="badge badge-pill badge-primary">
                <b>
                  ${priceDecimalFormatter(getPresetItemTotalPrice(presetItem))}
                </b>
                <span>/ {getPresetItemQuantity(presetItem)} quantity</span>
              </div>
            </div>
            <img
              className="restaurant"
              src={chefInfo.chefImage}
              alt="chefImage"
            />
          </div>
          <Slider className="slick preview mt-4" {...settings}>
            {images &&
              images.map(image => (
                <div key={image} className="slide">
                  <img src={image} alt="presetItemImage" />
                </div>
              ))}
          </Slider>
          <ul className="tags">
            {dietaryTypes
              .split(',')
              .map(
                dietaryType =>
                  dietaryType && <li key={dietaryType}>{dietaryType}</li>,
              )}
          </ul>
          {ReactHtmlParser(parsedDescription)}
        </div>
        <Formik
          {...{ initialValues, validationSchema }}
          validateOnChange
          validateOnBlur
          onSubmit={form => {
            const eachOrderValidation = form?.menuItems?.length > 0 ? (Object.values(form?.menuItemsMapping).every(value => value === 0) || Object.values(form?.menuItemsMapping).some(value => (value > 0 && value < chefInfo.minOrderAmount))) : form?.quantity < chefInfo.minOrderAmount;
            const newQuantity = getPresetItemQuantity(form);
            if (eachOrderValidation) {
              setMinOrderSelectionValid(false);
              return;
            }

            const newTotalPrice = getPresetItemTotalPrice(form);
            const formData = {
              ...form,
              quantity: newQuantity,
              priceCents: newTotalPrice / newQuantity,
            };
            onSubmit(presetItem, formData);
            setMinOrderSelectionValid(true);
            onClose();
          }}
        >
          {({ setFieldValue, values }) => {
            const totalQuantity = getPresetItemQuantity(values);
            const totalPrice = getPresetItemTotalPrice(values);
            return (
              <Form className="right">
                <div className="subtitle">
                  ORDER SELECTION (Minimum of {chefInfo.minOrderAmount})
                </div>
                <div className="list-group mb-4">
                  {menuItems.map(menuItem => {
                    const { id, name } = menuItem;
                    return (
                      <div key={id} className="list-group-item">
                        <div>
                          <span>{name}</span>
                          <div className="badge badge-pill badge-primary">
                            ${priceDecimalFormatter(menuItem.priceCents)}
                          </div>
                        </div>
                        <div className="counter">
                          <button
                            className="minus"
                            onClick={() =>
                              onQuantityChange(
                                `menuItemsMapping.${menuItem.id}`,
                                values.menuItemsMapping[menuItem.id] || 0,
                                setFieldValue,
                                -1,
                              )
                            }
                            type="button"
                          />
                          <Field
                            type="number"
                            className="counter__number"
                            name={`menuItemsMapping.${menuItem.id}`}
                            value={values.menuItemsMapping[menuItem.id] || 0}
                            onChange={e => onQuantityInput(e, setFieldValue)}
                          />
                          <button
                            className="plus"
                            onClick={() =>
                              onQuantityChange(
                                `menuItemsMapping.${menuItem.id}`,
                                values.menuItemsMapping[menuItem.id] || 0,
                                setFieldValue,
                              )
                            }
                            type="button"
                          />
                        </div>
                      </div>
                    );
                  })}
                  {menuItems.length === 0 && (
                    <div className="list-group-item">
                      <div>
                        <span>{name}</span>
                        <div className="badge badge-pill badge-primary">
                          ${priceDecimalFormatter(priceCents)}
                        </div>
                      </div>
                      <div className="counter">
                        <button
                          className="minus"
                          onClick={() =>
                            onQuantityChange(
                              'quantity',
                              values.quantity || 0,
                              setFieldValue,
                              -1,
                            )
                          }
                          type="button"
                        />
                        <Field
                          type="number"
                          className="counter__number"
                          name="quantity"
                          onChange={e => onQuantityInput(e, setFieldValue)}
                        />
                        <FormError name="quantity" />
                        <button
                          className="plus"
                          onClick={() =>
                            onQuantityChange(
                              'quantity',
                              values.quantity || 0,
                              setFieldValue,
                            )
                          }
                          type="button"
                        />
                      </div>
                    </div>
                  )}
                  <div className="list-group-item total">
                    <div className="entry mb-3">
                      <div className="title">TOTAL PORTIONS:</div>
                      <b className="value">{totalQuantity}</b>
                    </div>
                    <div className="entry">
                      <div className="title">TOTAL PRICE:</div>
                      <b className="value">
                        ${priceDecimalFormatter(totalPrice)}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="subtitle">SPECIAL INSTRUCTIONS</div>
                <Field
                  as="textarea"
                  className="form-control"
                  name="specialInstruction"
                  rows={3}
                  placeholder="Please type in any special instructions you have."
                />
                  {!minOrderSelectionValid && (
                    <OrderError
                      errorText={`Minimum order amount of ${chefInfo.minOrderAmount} per item not met. Please increase quantity and continue.`}
                    />
                  )}
                <button
                  className="mt-3 btn btn-block btn-primary"
                  type="submit"
                >
                  Save Changes
                </button>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

PresetItemView.propTypes = {
  presetItem: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    priceCents: PropTypes.number,
    servingStyle: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    averageRating: PropTypes.number,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        priceCents: PropTypes.number,
      }),
    ),
    dietaryTypes: PropTypes.string, // string joined by comma ,
  }),
  initialValues: PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.number, PropTypes.string]), // string for additional items
    presetMenuCategoryName: PropTypes.string,
    chefId: PropTypes.number,
    menuId: PropTypes.number,
    quantity: PropTypes.number,
    specialInstruction: PropTypes.string,
    menuItemsMapping: PropTypes.shape({}),
    menuItems: PropTypes.arrayOf(PropTypes.shape({})),
    priceCents: PropTypes.number,
  }),
  chefInfo: PropTypes.shape({
    chefImage: PropTypes.string,
    menuType: PropTypes.string,
    minOrderAmount: PropTypes.number,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

PresetItemView.defaultProps = {
  presetItem: null,
  initialValues: {},
  chefInfo: {},
  onClose: () => { },
  onSubmit: () => { },
};
export default PresetItemView;
