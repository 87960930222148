import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory, NavLink } from 'react-router-dom';
import { store } from 'react-notifications-component';
import debounce from 'lodash/debounce';

import { showSignInModal, showSignUpModal } from 'redux/actions/modalActions';
import TopBarSearch from 'components/common/TopBarSearch';
import { getTopBarSearchResult } from 'api/searchApi';
import Dropdown from 'react-bootstrap/Dropdown';
import { signoutRequest } from 'redux/actions/authActions';
import { BROWSE_RESTAURANT_URL } from 'consts/presets';
import { topBarSearchFocused } from 'redux/actions/searchActions';
import VoidLink from './VoidLink';

import './Header.scss';

const Header = ({
  showSignInModal,
  showSignUpModal,
  signoutRequest,
  topBarSearchFocused,
  user,
  inputFocused,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [presets, setPresets] = useState([]);
  const [query, setQuery] = useState('');
  const inputRef = useRef();
  const userSignedIn = user && user.confirmed;
  const [isToggled, setToggled] = useState(false);
  const [isSearch, setSearch] = useState(false);

  const debouncedSearch = debounce(async () => {
    if (query && query.length >= 3) {
      try {
        const { presets } = await getTopBarSearchResult(query);
        setPresets(presets);
      } catch (error) {
        console.log('--> presets loading failed', error);
        setPresets([]);
      }
    } else {
      setPresets([]);
    }
  }, 500); // debounced search required - performance issue

  function queryChange(newQuery) {
    setQuery(newQuery);
  }

  useEffect(() => {
    setQuery('');
    setPresets([]);
  }, [pathname]); // whenever visit new url, reset the result

  useEffect(() => {
    // check if we need to prevent body scroll or not
    if (query && query.length >= 3) {
      // show overlay so prevent
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    debouncedSearch();

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [query]);

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputFocused]);

  const showComingSoon = () =>
    store.addNotification({
      title: 'Coming soon!',
      message:
        'Please bare with us - we are updating your dashboard. Check back later. Thank you!',
      type: 'info',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
      },
    });

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-light">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMenu"
            onClick={() => {
              setToggled(true);
            }}
          >
            <svg className="icon icon-menu">
              <use xlinkHref="#spriteIcon-menu" />
            </svg>
          </button>
          <div className="left">
            <Link className="logo" to="/">
              <img src="/assets/img/catered_club_logo.svg" alt="logo" />
            </Link>
            <form className="search-form ml-4 pl-2 hide-mobile">
              <div
                className={classnames('input-with-icons ii-left ii-right', {
                  'animated shake': inputFocused,
                })}
              >
                <input
                  ref={inputRef}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={query}
                  onChange={e => queryChange(e.currentTarget.value)}
                  onBlur={() => {
                    topBarSearchFocused(false);
                  }}
                />
                <div className="input-prepend">
                  <svg className="icon icon-search">
                    <use xlinkHref="#spriteIcon-search" />
                  </svg>
                </div>
                <div className="input-append">
                  <svg className="icon icon-close">
                    <use xlinkHref="#spriteIcon-close" />
                  </svg>
                </div>
              </div>
            </form>
          </div>
          <div className="navbar-menu">
            {userSignedIn && (
              <ul className="nav hide-mobile ml-4 pl-2">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary">
                    Hi {user.firstName}!
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/settings');
                      }}
                    >
                      Account Details
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/payments');
                      }}
                    >
                      Payment Methods
                    </Dropdown.Item>
                    {user?.id && (
                      <Dropdown.Item
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_GROUP_ORDER_APP_URL}grouporder-dashboard/${user.id}`,
                            '_blank',
                          );
                        }}
                      >
                        Group Order Dashboard
                      </Dropdown.Item>
                    )}{' '}
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/favorites');
                      }}
                    >
                      My Favorites & Saved Orders
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        history.push('/my-orders');
                      }}
                    >
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        signoutRequest();
                      }}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            )}
            {!userSignedIn && (
              <ul className="nav hide-mobile">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-link"
                    data-toggle="modal"
                    data-target="#modalSignIn"
                    onClick={() => {
                      showSignInModal();
                    }}
                  >
                    Sign In
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#modalSignUp"
                    onClick={() => {
                      showSignUpModal();
                    }}
                  >
                    Sign Up
                  </button>
                </li>
              </ul>
            )}
            <ul className="nav text-primary">
              <li className="nav-item hide-desktop">
                <VoidLink
                  onClick={() => setSearch(prev => !prev)}
                  className="nav-link"
                >
                  <svg className="icon icon-search">
                    <use xlinkHref="#spriteIcon-search" />
                  </svg>
                </VoidLink>
                {/* <a
                  className="nav-link"
                  href="javascript:void(0)"
                  data-toggle="collapse"
                  data-target="#searchForm"
                /> */}
              </li>
              <li className="d-none nav-item position-relative">
                {/* <VoidLink className="nav-link">
                  <svg className="icon icon-cart">
                    <use xlinkHref="#spriteIcon-cart" />
                  </svg>
                </VoidLink> */}
                {/* <a
                  className="nav-link"
                  href="javascript:void(0)"
                  data-toggle="popover-custom"
                  data-target="#popoverCart"
                /> */}
                <div
                  className="popover popover-custom bs-popover-bottom fade"
                  id="popoverCart"
                  role="tooltip"
                >
                  <div className="popover-body">
                    <a
                      className="btn btn-block btn-outline-primary mb-4"
                      href="detailed_view-two.html"
                    >
                      View Current Cart
                    </a>
                    <div className="title">Saved Carts</div>
                    <ul>
                      <li>
                        <VoidLink>
                          <span className="name">Teams Favorite</span>
                          <span className="items">5 items</span>
                        </VoidLink>
                      </li>
                      <li>
                        <VoidLink>
                          <span className="name">Reasonable</span>
                          <span className="items">6 items</span>
                        </VoidLink>
                      </li>
                      <li>
                        <VoidLink>
                          <span className="name">Party Choice</span>
                          <span className="items">16 items</span>
                        </VoidLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="backdrop" />
              </li>
            </ul>
            <div className={`navbar-collapse ${isToggled && 'show'}`}>
              <button
                type="button"
                aria-label="toggle-button"
                className="back navbar-toggler mt-2"
                onClick={() => {
                  setToggled(false);
                }}
              />
              <ul className="navbar-nav mr-xl-auto">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    exact
                    to="/"
                    onClick={() => {
                      setToggled(false);
                    }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/about-us"
                    onClick={() => {
                      setToggled(false);
                    }}
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/faq"
                    onClick={() => {
                      setToggled(false);
                    }}
                  >
                    Faq
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/browse-restaurants"
                    onClick={() => {
                      setToggled(false);
                    }}
                  >
                    Browse Restaurants
                  </NavLink>
                </li>
                {/*
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/browse-presets"
                    onClick={() => {
                      setToggled(false);
                    }}
                  >
                    Browse Preset Menus
                  </NavLink>
                </li>
                */}
                {!userSignedIn && (
                  <>
                    <li className="nav-item more hide-desktop">
                      <VoidLink
                        className="nav-link"
                        onClick={() => {
                          setToggled(false);
                          showSignInModal();
                        }}
                      >
                        Sign In
                      </VoidLink>
                    </li>
                    <li className="nav-item more hide-desktop">
                      <VoidLink
                        className="nav-link"
                        onClick={() => {
                          setToggled(false);
                          showSignUpModal();
                        }}
                      >
                        Sign Up
                      </VoidLink>
                    </li>
                  </>
                )}
                {userSignedIn && (
                  <>
                    <li className="nav-item mr-0 hide-desktop">
                      <NavLink
                        className="nav-link"
                        to="/settings"
                        onClick={() => {
                          setToggled(false);
                        }}
                      >
                        Account Details
                      </NavLink>
                    </li>
                    <li className="nav-item mr-0 hide-desktop">
                      <NavLink
                        className="nav-link"
                        to="/payments"
                        onClick={() => {
                          setToggled(false);
                        }}
                      >
                        Payment Methods
                      </NavLink>
                    </li>
                    <li className="nav-item mr-0 hide-desktop">
                      {user?.id && (
                        <Dropdown.Item
                          className="nav-link"
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_GROUP_ORDER_APP_URL}grouporder-dashboard/${user.id}`,
                              '_blank',
                            );
                          }}
                        >
                          Group Order Dashboard
                        </Dropdown.Item>
                      )}{' '}
                    </li>
                    <li className="nav-item mr-0 hide-desktop">
                      <NavLink
                        className="nav-link"
                        to="/favorites"
                        onClick={() => {
                          setToggled(false);
                        }}
                      >
                        My Favorites & Saved Orders
                      </NavLink>
                    </li>
                    <li className="nav-item mr-0 hide-desktop">
                      <NavLink
                        className="nav-link"
                        to="/my-orders"
                        onClick={() => {
                          setToggled(false);
                        }}
                      >
                        Orders
                      </NavLink>
                    </li>
                    <li className="nav-item more logout">
                      <button
                        className="nav-link text-left"
                        type="button"
                        onClick={() => {
                          setToggled(false);
                          signoutRequest();
                        }}
                      >
                        Sign Out
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="backdrop" />
          </div>
        </div>
        {isSearch && (
          <div className="container pt-2 hide-desktop">
            <form className="search-form">
              <div
                className={classnames('input-with-icons ii-left ii-right', {
                  'animated shake': inputFocused,
                })}
              >
                <input
                  ref={inputRef}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={query}
                  onChange={e => queryChange(e.currentTarget.value)}
                  onBlur={() => {
                    topBarSearchFocused(false);
                  }}
                />
                <div className="input-prepend">
                  <svg className="icon icon-search">
                    <use xlinkHref="#spriteIcon-search" />
                  </svg>
                </div>
                <div className="input-append">
                  <svg className="icon icon-close">
                    <use xlinkHref="#spriteIcon-close" />
                  </svg>
                </div>
              </div>
            </form>
          </div>
        )}
      </nav>
      <TopBarSearch
        {...{ presets, query, userSignedIn }}
        onClose={() => {
          setQuery('');
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user && state.user.user,
  inputFocused: state.searchResult && state.searchResult.inputFocused,
});

const mapDispatchToProps = {
  showSignInModal,
  showSignUpModal,
  signoutRequest,
  topBarSearchFocused,
};

Header.propTypes = {
  showSignInModal: PropTypes.func.isRequired,
  showSignUpModal: PropTypes.func.isRequired,
  signoutRequest: PropTypes.func.isRequired,
  topBarSearchFocused: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    confirmed: PropTypes.bool,
  }).isRequired,
  inputFocused: PropTypes.bool.isRequired,
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export default connectedHeader;
