import React from "react";
import cn from 'classnames'
import { useField, useFormikContext } from "formik";
import FormInputOutput from "./FormInputOutput";
import FormInputError from "./FormInputError";

const FormInput = ({
    id,
    className,
    classContainer,
    label,
    name,
    value,
    type,
    error = false,
    styleError,
    required = false,
    readonly,
    placeholder,
    inputWrapper,
    withoutContainer = false,
    children,
    inputComponent,
    options,
    numberZeroValue = true,
    disabled,
    childrenPosition = 'after'
}) => {

    const [field] = useField(name);

    const { errors } = useFormikContext();

    const inputClassName = (className) ? className : 'form-item'

    let inputProps = {
        ...field,
        required: required,
        id: id ? id : null,
        disabled: disabled || false,
        readOnly: readonly ? readonly : false,
        value: field.value ? field.value : '',
        type: type ? type : 'text',
        placeholder: placeholder,
        className: cn(inputClassName, { 'error': errors[name] || error }),
        ...options,
    }

    if (inputProps.type === 'select') {
        delete inputProps.type
    }

    if (inputProps.type === 'radio') {
        inputProps.value = value;
        inputProps.checked = field.value === value
    }

    if (inputProps.type === 'number' && !inputProps.value && numberZeroValue) {
        inputProps.value = 0;
    }

    const BaseInput = () => (inputComponent) ?
        React.createElement(inputComponent, inputProps, children) :
        React.createElement('input', inputProps)


    const FormLabel = () => (label) ? <label htmlFor={name} className="form-label">{label}</label> : '';

    const InputContentInner = () => {
        return (
            <>
                {(childrenPosition === 'before') ? children : null}
                {BaseInput()}
                <FormInputError style={styleError} name={name} />
                {(childrenPosition === 'after') ? children : null}
            </>
        )
    }

    const InputContent = () => (inputWrapper) ?
        <div className={inputWrapper}>{InputContentInner()}</div> :
        InputContentInner()

    return (
        <FormInputOutput className={classContainer} withoutContainer={withoutContainer}>
            {FormLabel()}
            {InputContent()}
        </FormInputOutput>
    )
}

export default FormInput;
